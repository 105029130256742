/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql} from "gatsby"
import Navigation from "../components/navigation";
import Footer from "../components/footer";


import * as styles from "../components/layout.module.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          
        }
      }
    }
  `)



  console.log(data.site);

  return (
    <>
       <Navigation />
     
        <main className={styles.wrapper}>{children}</main>
      <Footer/>
       
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
