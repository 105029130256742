import Layout from "../components/layout"
import * as React from 'react'
import { graphql } from "gatsby"
import * as styles from "../components/project.module.css"
import VideoFrame from '../components/VideoFrame';
//import { GatsbyImage, getImage } from 'gatsby-plugin-image'
//import { useState, useEffect } from "react";
import { Link } from "gatsby"


import { GatsbyImage, getImage } from "gatsby-plugin-image"

const projectTemplate = ({ data }) => {
  const project = data.strapiProject;
  
  return (
    <Layout>
      <div className={styles.introWrapper}>
        <div>
          <div className={styles.titleFlex}>
            <h1 className={styles.title}>{project.title}</h1>
            <p className={styles.date}>{project.date}</p>
          </div>
          <p className={styles.introText}>{project.intro}</p>
        </div>
        {/*Nog een if toevoegen, niet alles heeft een link */}
      <a href={project.url} className={project.url ? styles.buttonProject : styles.invisible}>
          <p>{project.encouragment}</p>
          <svg className={styles.buttonSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" aria-hidden="true"><polygon points="9.48 14.5 8.42 13.44 13.86 8 8.42 2.56 9.48 1.5 15.98 8 9.48 14.5"/><rect x="0" y="7.25" width="14.92" height="1.5"/></svg>
        </a>
      </div>  


      <div className={styles.photogrid}>
          {project.photos.map(photo => {
                return(
                  <GatsbyImage
                        key={photo.hash}
                        className={`${(photo.height > 1100) ? styles.column : styles.photo} ${(photo.width > 1100) ? styles.row : styles.photo}`}
                        alt="Linked link"
                        image={getImage(photo.localFile)}/>
            )
          })}
      </div>

      <div className={styles.extraExplanation}>
        <div>
          <p className={styles.extraExplanationTitle}>Briefing</p>
           <p>{project.briefing}</p>
          
        </div>
        <div className={styles.approachWrapper}>
          <p className={styles.extraExplanationTitle}>Approach</p>
          <p className={styles.approachWrapperText}>{project.approach}</p>
          
        </div>
      </div>

      {project.url_video &&
        <div className={styles.video}>
        <VideoFrame  videoSrcURL={project.url_video} videoTitle={project.title_video}/>
        </div>
        }
        <Link className={styles.back} to="/projects">Terug naar overzicht</Link>
  
   </Layout>
  )
}

export default projectTemplate;


//wat moet er hier staan? id of strapi_id??
export const pageQuery = graphql`
  query ProjectTemplate($id: Int!) {
    strapiProject(strapi_id: {eq: $id}) {
      id
      strapi_id
      title
      intro
      date
      encouragment
      briefing
      approach
      url_video
      title_video

      url
       photos {
        height
        width
        hash
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }

      }
    }
  }
`