// extracted by mini-css-extract-plugin
export var approachWrapper = "project-module--approachWrapper--wxf92";
export var approachWrapperText = "project-module--approachWrapperText---H3so";
export var back = "project-module--back--2x5Yw";
export var buttonProject = "project-module--buttonProject--ETivv";
export var buttonSvg = "project-module--buttonSvg--v9s3F";
export var column = "project-module--column--c6KlS";
export var content = "project-module--content--pu9NM";
export var date = "project-module--date--IQUed";
export var extraExplanation = "project-module--extraExplanation--rwryO";
export var extraExplanationTitle = "project-module--extraExplanationTitle--I1+Oh";
export var introText = "project-module--introText--Ds+yR";
export var introWrapper = "project-module--introWrapper--5tkH2";
export var invisible = "project-module--invisible--dZRc8";
export var photogrid = "project-module--photogrid--6RaHk";
export var row = "project-module--row--pBA6z";
export var title = "project-module--title--CAXQB";
export var titleFlex = "project-module--titleFlex--czBQ0";
export var video = "project-module--video--Ta5PT";