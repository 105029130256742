import * as React from "react"
//import { Link } from "gatsby"
import * as styles from "./VideoFrame.module.css"

//import { GatsbyImage, getImage } from "gatsby-plugin-image"



const Video = ({ videoSrcURL, videoTitle}) => (
  <div className={styles.vimeoVideo_wrapper}>
    <iframe
    className={styles.vimeoVideo}
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      //frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)
export default Video

/*

<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/824195563?h=36a6b3aa84&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="3D hero image | Le creuset"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>*/